@import 'main.scss';



.no-show {display: none;}

/*geolocation*/
.geo-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000;

	.geo-wrapper {
		background-color: #fff;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 278px;
		height: 278px;
		
		@include respond-to(sm) {
			width: 500px;
			height: 250px;
		}

		@include respond-to(md) {
			width: 898px;
			height: 345px;
		}

		.geo-close {
		    float: right;
		    cursor: pointer;
		    margin-top: -12px;
		    margin-right: -12px;
		    border-radius: 50%;
		    width: 25px;
		    height: 25px;
		    background-color: #fff;

		    span {
				@include sprite($x-black-mobile);

				@include respond-to(sm) {
					@include sprite($x-black-laptop);
				}

		    	@include respond-to(md) {
		    		@include sprite($x-black-desktop);
		    	}
		    }

		    @include respond-to(sm) {
				margin-top: -12px;
		    	margin-right: -12px;
		    	border-radius: 50%;
			    width: 32px;
			    height: 32px;
			}

			@include respond-to(md) {
				width: 40px;
				height: 40px;
				border-radius: none;
				margin: 20px 20px 20px 0;
				background-color: none;
			}

		}

		.geo-title {
			clear: both;
			text-align: center;

			h3 {
				line-height: 130%;
				margin: 0 auto;
				margin-top: 44px;
				width: 230px;

				@include respond-to(sm) {
					width: 430px;
					line-height: 120%;
				}

				@include respond-to(md) {
					margin-top: 0;
					width: 700px;
				}
			}
		}

		.form-group {
			position: relative;
			width: 250px;
			height: 44px;
			margin: 0 auto;
			margin-top: 30px;

			@include respond-to(sm) {
				width: 234px;
				height: 50px;
				margin-top: 40px;
			}

			@include respond-to(md) {
				width: 318px;
				height: 44px;
				margin-top: 40px;
			}

			input[type="text"] {
				background-color : #f1f1f1;
			}

			.pin-black {
			    position: absolute;
			    left: 14px;
			    top: 13px;
			}

			.search-box {
				font-size: 16px;
				border: none;
				height: 42px;
				padding-left: 42px;
			}

			.arrow-right-white {
			    margin-left: 15px;
			    margin-top: 16px;
			}

			.geo-go-search {
				position: absolute;
				display: block;
				top: 0;
				right: 0;
				width: 44px;
				height: 42px;
				background-color: #f30000;
				cursor: pointer;
			}

		}

		.zip-error {
			text-align: center;
    		margin-top: 19px;
    		color: #f30000;
		}

	}

}
/*geolocation*/